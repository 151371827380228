import {useLocation, useParams} from "react-router-dom";
import PageTitle from "../components/layout/PageTitle";
import {Container} from "react-bootstrap";


const ErrorPage = () => {


    const {code} = useParams()

    const {state}  = useLocation()

    return (
        <Container>
            <PageTitle title={`Error ${code !==null && code}`} />

            <div className="text-secondary">{state && state.message}</div>
        </Container>
    )
}


export default ErrorPage