import {useState} from "react";
import {AuthContext} from "../hooks/useAuth";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router";

const AuthProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState(
        localStorage.getItem('access_token') &&
        jwtDecode(localStorage.getItem('access_token')));
    const [idToken, setIdToken] = useState(localStorage.getItem('gcp_id_token'));
    const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));
    const navigate = useNavigate();

    // To be called when you get the auth token
    const addToken = (token, type = "id") => {

        if (type === "access_token") {
            setAccessToken(token);
            localStorage.setItem("access_token", token);
            setCurrentUser(token);
            const decodedToken = jwtDecode(token);

            // if not registered mfc user, clear localstorage and redirect
            if (decodedToken.mfc_reg === false) {
                localStorage.clear()
                navigate('/errors/401', {state: {message: 'Please contact your administrator to be added'}});
            } else {
                setCurrentUser(decodedToken)
            }

        } else {
            setIdToken(token);
            localStorage.setItem("gcp_id_token", token);
        }
    }

    const authorize = (client_id, callback_uri, referrer_uri, scopes) => {

        // construct URI
        let googleOAuthRequest = `https://accounts.google.com/o/oauth2/v2/auth?`
        googleOAuthRequest += `scope=${scopes}`
        googleOAuthRequest += `&include_granted_scopes=false`
        googleOAuthRequest += `&response_type=token`
        googleOAuthRequest += `&state=${referrer_uri}`
        googleOAuthRequest += `&redirect_uri=${callback_uri}`
        googleOAuthRequest += `&client_id=${client_id}`

        window.location.href = googleOAuthRequest;
    }


    // To be called when you want to log out
    const signOut = () => {
        setCurrentUser(null);
        localStorage.clear()
    }

    const value = {currentUser, idToken, accessToken, addToken, authorize, signOut};

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;