import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Container} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useAuth} from "../../hooks/useAuth";


const Header = () => {

    const links = [
        {
            "text": "Events",
            "isProtected": true,
            "href": "/events"
        },
        {
            "text": "Teams",
            "isProtected": true,
            "href": "/teams"
        },
        {
            "text": "People",
            "isProtected": true,
            "href": "/people"
        }
    ]

    const navigate = useNavigate();
    const auth = useAuth();
    const isAuthenticated = auth && auth.currentUser !== null

    const handleLogout = () => {
        auth.signOut()
        navigate('/sign-in')
    }

    const renderLinks = () => {
        return links.map((link, index) => {
            if ((link.isProtected && !isAuthenticated) || (isAuthenticated && link.hideWhenAuthenticated)) {
                return null;
            }
            return (
                <Nav.Link key={index} href={null}
                          onClick={link.onClick ? eval(link.onClick) : () => navigate(link.href)}>
                    {link.text}
                </Nav.Link>
            );
        });
    }


    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand className="m-0 p-0"  onClick={() => navigate('/')}>
                    <span className="logo-red">my</span>
                    <span className="logo-black mx-1">Events Hub</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" collapseOnSelect={true}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto header">
                        {renderLinks()}
                    </Nav>
                    <Nav className={!isAuthenticated ? "d-none" : "d-flex"}>
                        <Nav.Link onClick={handleLogout}>
                            <span className="bi-box-arrow-left small"/>
                            <span className="small mx-1">Logout</span>
                        </Nav.Link>
                    </Nav>
                    <Nav className={isAuthenticated ? "d-none" : "d-flex"}>
                        <Nav.Link onClick={handleLogout}>
                            <span className="small mx-1">Sign In</span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
}

export default Header;