import React, {Fragment, useEffect, useRef, useState} from "react"
import {Button} from "react-bootstrap";
import {jwtDecode} from "jwt-decode";


/*
All code in this component is based on the following documentation:
https://developers.google.com/identity/gsi/web/guides/overview
*/

const GoogleOneTapSignIn = ({client_id, callback, type = "button"}) => {
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
    const buttonRef = useRef();

    useEffect(() => {

        const initializeGsi = () => {
            setGsiScriptLoaded(true)
            window.google.accounts.id.initialize({
                client_id: client_id, callback: handleGoogleSignIn,
            })
        }


        const script = document.createElement("script")
        script.src = "https://accounts.google.com/gsi/client"
        script.onload = initializeGsi
        script.async = true
        script.id = "google-client-script"
        document.querySelector("body")?.appendChild(script)

        return () => {
            // Cleanup function that runs when component unmounts
            window.google?.accounts.id.cancel()
            document.getElementById("google-client-script")?.remove()
            document.getElementById("g_id_onload")?.remove()
            document.getElementById("g_id_signin")?.remove()
        }

    }, [client_id, gsiScriptLoaded]);

    const handleGoogleSignIn = (res) => {
        // const responsePayload = jwtDecode(res.credential);
        // callback(responsePayload);
        callback(res.credential)
    }



    const googleButton =
        <div id="g_id_test"
             className="g_id_signin text-center"
             data-type="standard"
             data-callback="handleGoogleSignIn"
             data-size="large"
             data-theme="outline"
             data-text="sign_in_with"
             data-shape="rectangular"
             data-logo_alignment="left">
        </div>

    const googlePopup =
        <div
             id="g_id_onload"
             data-client_id={client_id}
             data-context="signin"
            // data-callback="handleGoogleSignIn"
             data-nonce=""
             data-itp_support="true">
        </div>

    return (
        <>
            {type === "popup" && googlePopup}
            {type === "button" && googleButton}
        </>
    )

}

export default GoogleOneTapSignIn;