import {Button, Col, FloatingLabel, Modal, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useEffect, useState} from "react";
import UserService from "../../services/UserService";
import {emailValidator, requiredValidator} from "../../utils/inputValidators";


export const AddPerson = ({show, onHide, onCreated}) => {

    const [showDialog, setShowDialog] = useState(show);
    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState({});


    useEffect(() => {
        setShowDialog(show);
    }, [show]);

    const handleClose = () => {
        setShowDialog(false)
        setFormData({})
        setFormErrors({});
        onHide();
    }

    const handlePersonAdd = () => {
        if (handleValidate()) {
            UserService.add_user(formData).then(res => {
                setFormData({})
                onCreated && onCreated()
                handleClose && handleClose()
            })

        }
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});

    }

    const handleValidate = () => {

        let errors = {};

        !emailValidator(formData.email) ?
            errors.email = "Invalid email address" :
            delete errors.email;

        !requiredValidator(formData.firstName, 3) ?
            errors.firstName = "First Name is required" :
            delete errors.firstName;

        !requiredValidator(formData.lastName, 3) ?
            errors.lastName = "Last Name is required" :
            delete errors.lastName;

        setFormErrors({...errors})

        return Object.keys(errors).length === 0;


    }

    const addPersonForm =
        <>
            <div>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Email*"
                    className="mb-3 text-secondary"
                >
                    <Form.Control name="email" required type="input"
                                  maxLength={100}
                                  value={formData.email}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors.email}
                                  placeholder="Email Address"
                    />
                    <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
                </FloatingLabel>
            </div>
            <div>
                <FloatingLabel
                    controlId="floatingInput"
                    label="First Name*"
                    className="mb-3 text-secondary"
                >
                    <Form.Control name="firstName" required type="input"
                                  maxLength={100}
                                  value={formData.firstName}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors.firstName}
                                  placeholder="First Name"
                    />
                    <Form.Control.Feedback type="invalid">{formErrors.firstName}</Form.Control.Feedback>
                </FloatingLabel>
            </div>
            <div>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Last Name*"
                    className="mb-3 text-secondary"
                >
                    <Form.Control name="lastName" required type="input"
                                  maxLength={100}
                                  value={formData.lastName}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors.lastName}
                                  placeholder="Last Name"
                    />
                    <Form.Control.Feedback type="invalid">{formErrors.lastName}</Form.Control.Feedback>
                </FloatingLabel>
            </div>
        </>

    return (
        <>
            <Modal show={showDialog} onHide={handleClose}>
                <Modal.Header closeButton closeVariant='white' className="modal-header small">
                    <Modal.Title className="">Add Person</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {addPersonForm}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-default" onClick={handlePersonAdd}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}