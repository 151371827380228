import {Container} from "react-bootstrap";
import PageTitle from "../components/layout/PageTitle";


export const TeamsPage = () => {

    return (
        <Container className="pt-3">
            <PageTitle title="Teams" />
            Placeholder Teams page
        </Container>
    )
}