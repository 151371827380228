import eventsAPI from "./eventsAPI";

/**
 * Abstraction services Class for CRUD operations on User.
 */

class UserService {


    static get_users() {


        return eventsAPI().get(`/users`).then((res) => {
            if (res !== undefined) {
                return res.data;
            } else {
                return []
            }

        }).catch((err) => {
            throw err;
        });
    }

    static add_user(person) {

        const newPerson = {
            "email": person.email,
            "first_name": person.firstName,
            "last_name": person.lastName
        }

        return eventsAPI().post(`/users`, newPerson).then((res) => {
            return res.data;
        }).catch((err) => {
            throw err;
        });
    }
}


export default UserService;