const PageTitle = ({title}) => {

    return (
        <div className="d-flex justify-content-between">
                <span className="page-title">
                    {title}
                </span>
        </div>
    )
}

export default PageTitle;