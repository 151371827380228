import {useEffect, useState} from "react";
import UserService from "../../services/UserService";
import * as users from "react-bootstrap/ElementChildren";
import {useAuth} from "../../hooks/useAuth";

const PeopleList = ({people = []}) => {


    const renderList = () => {

        return people.map(person => (
            <div key={person._id} className="pb-2">
                {`${person.first_name} ${person.last_name}`}
                <div className="small text-secondary">{person.email}</div>

            </div>
        ))
    }

    return (
        <>
            {renderList()}
        </>
    )
}

export default PeopleList;