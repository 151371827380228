
export const EventDetails = ({ event }) => {
    return (
        <div>
            <div className="small">
                {event.event_description}
            </div>
            <div className="small text-secondary">
                <span className="bi-calendar-event me-1"/>
                {new Date(event.start_time).toDateString()}
            </div>
        </div>
    )
}