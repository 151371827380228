import PageTitle from "../components/layout/PageTitle";
import {useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import {useEffect, useState} from "react";
import EventService from "../services/EventService";
import {EventDetails} from "../components/events/EventDetails";


export const EventPage = ({}) => {

    const {eventId} = useParams()
    const [event, setEvent] = useState({});

    useEffect(() => {

        EventService.get_event(eventId).then(res => {
            setEvent(res)
        })

    }, [eventId])

    return (
        <Container>
            <PageTitle title={event && event.event_name}/>
            <EventDetails event={event}/>
        </Container>
    )


}