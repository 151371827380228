import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import {Routes, Route} from 'react-router-dom';

import './App.css';
import SignIn from "./pages/SignIn";
import AuthProvider from "./components/AuthProvider";
import ScheduleListView from "./pages/ScheduleListView";
import Header from "./components/layout/Header";
import {EventsPage} from "./pages/EventsPage";
import {TeamsPage} from "./pages/TeamsPage";
import {PersonsPage} from "./pages/PersonsPage";
import {useAuth} from "./hooks/useAuth";
import ErrorPage from "./pages/ErrorPage";
import {LandingPage} from "./pages/LandingPage";
import {EventPage} from "./pages/EventPage";

function App() {
    return (
        <>
            <AuthProvider>
                <Header/>
                <AppRoutes/>
            </AuthProvider>
        </>
    );
}


const AppRoutes = () => {

    const auth = useAuth()

    const protectedRoutes = [
        <Route path="/events" element={<EventsPage/>}/>,
        <Route path="/events/:eventId" element={<EventPage/>}/>,
        <Route path="/teams" element={<TeamsPage/>}/>,
        <Route path="/people" element={<PersonsPage/>}/>
    ]

    return (
        <Routes>
            {auth.currentUser !== null && protectedRoutes}
            <Route path="/" element={<LandingPage/>}/>
            <Route path="sign-in" element={<SignIn/>}/>
            <Route key="error" path="/errors/:code" element={<ErrorPage/>}/>
        </Routes>
    )
}

export default App;
