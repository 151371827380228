import eventsAPI from "./eventsAPI";

/**
 * Abstraction services Class for CRUD operations on User.
 */

class AuthService {

    static get_access_token(gcp_id_token) {

        return eventsAPI().get(`/auth/google/id_token/${gcp_id_token}`).then((res) => {
            return res.data;
        }).catch((err) => {
            throw err;
        });
    }
}


export default AuthService;