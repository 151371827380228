import {Button, Container} from "react-bootstrap";
import PageTitle from "../components/layout/PageTitle";
import {useEffect, useState} from "react";
import EventsList from "../components/events/EventsList";
import UserService from "../services/UserService";
import EventService from "../services/EventService";
import {AddPerson} from "../components/people/AddPerson";
import {AddEvent} from "../components/events/AddEvent";


export const EventsPage = () => {


    const [showAddEvent, setShowAddEvent] = useState(false);
    const [events, setEvents] = useState([]);

    useEffect(() => {

        EventService.get_all_events().then(res => {
            if (res !== undefined) {
                setEvents(res);
            }

        })

    }, []);

    const handleAddEvent = () => {
        EventService.get_all_events().then(res => {
            if (res !== undefined) {
                setEvents(res);
            }

        })
    }

    return (
        <Container className="pt-3">
            <div className="d-flex justify-content-between">
                <PageTitle title="Events"/>
                <Button variant="link" className="link-button" onClick={() => {
                    setShowAddEvent(!showAddEvent)
                }}>New Event</Button>
            </div>
            <AddEvent show={showAddEvent} onHide={() => {setShowAddEvent(false)}} onCreated={handleAddEvent} />


            <EventsList events={events}/>
        </Container>
    )
}