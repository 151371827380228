import {useNavigate} from "react-router";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

const EventsList = ({events = []}) => {

    const navigate = useNavigate();

    const renderList = () => {

        return events.map(event => (
            <div key={event._id} className="pb-2">
                <div className="h5 mb-0">
                    <Link to={`/events/${event._id}`}>
                        {`${event.event_name}`}
                    </Link>
                </div>
                <div className="small">
                    {event.event_description}
                </div>
                <div className="small text-secondary">
                    <span className="bi-calendar-event me-1"/>
                    {new Date(event.start_time).toDateString()}
                </div>
            </div>
        ))
    }

    return (
        <>
            {renderList()}
        </>
    )
}

export default EventsList;