import {Button, Container} from "react-bootstrap";
import PageTitle from "../components/layout/PageTitle";
import PeopleList from "../components/people/PeopleList";
import {AddPerson} from "../components/people/AddPerson";
import {useEffect, useState} from "react";
import UserService from "../services/UserService";


export const PersonsPage = () => {

    const [showAddUser, setShowAddUser] = useState(false);
    const [people, setPeople] = useState([]);

    useEffect(() => {

        UserService.get_users().then(res => {
            if (res !== undefined) {
                setPeople(res);
            }

        })

    }, []);

    const handleAddPerson = () => {
        UserService.get_users().then(res => {
            if (res !== undefined) {
                setPeople(res);
            }

        })
    }

    return (
        <Container className="pt-3">
            <div className="d-flex justify-content-between">
                <PageTitle title="People" />
                <Button variant="link" className="link-button" onClick={() => {setShowAddUser(!showAddUser)}}>Add Person</Button>
            </div>
            <AddPerson show={showAddUser} onHide={() => {setShowAddUser(false)}} onCreated={handleAddPerson} />


            <PeopleList people={people} />


        </Container>
    )
}