import axios from "axios";


const eventsAPI = () => {

    const instance = axios.create({
        baseURL: 'http://localhost:8000/v1',
        timeout: 50000,
        headers: {'Authorization': `Bearer ${localStorage.getItem("access_token")}`},
    })

    instance.interceptors.request.use((req) => {
        req.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`
        return req;
    }, (err) => {
        //    do nothing
    })

    instance.interceptors.response.use((res) => {
        return res;
    }, (err) => {
        if (err.response && err.response.status === 401) {
            // clear localStorage
            localStorage.clear();
            // navigate to sign in page
            window.location.replace('/sign-in');
        }

    })

    return instance;

}

export default eventsAPI;
