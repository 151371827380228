import {Container} from "react-bootstrap";
import PageTitle from "../components/layout/PageTitle";
import GoogleOneTapSignIn from "../components/GoogleOneTapSignIn";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router";
import AuthService from "../services/AuthService";


const SignIn = () => {

    const oauth_client_id = "200967243418-7lmo5q3mo3vlgnsfvh5obsgsfiql2nsc.apps.googleusercontent.com"
    const navigate = useNavigate();

    const auth = useAuth();

    const handleLogin = (id_token) => {
        console.log(id_token);
        auth.addToken(id_token);

        AuthService.get_access_token(id_token).then(access_token => {
            auth.addToken(access_token, 'access_token');
        })

        navigate('/');
    }

    return (
        <Container>
            <div className="d-flex justify-content-between pt-5">
                <PageTitle title="Sign In"/>
            </div>
            <div className="pt-5">

                <div className="pb-3 alert alert-warning col-md-6 small m-auto">
                    <span className="bi bi-exclamation-circle-fill text-primary-emphasis m-2"></span>
                    Currently only Sign In with Google is supported.
                </div>

                <div className="row pt-5">
                    <div className="col"></div>
                    <div className="col-10 col-md-2 text-center">
                        <GoogleOneTapSignIn client_id={oauth_client_id} callback={handleLogin}/>
                    </div>
                    <div className="col"></div>
                </div>

            </div>

        </Container>
    )

}

export default SignIn;