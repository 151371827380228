import eventsAPI from "./eventsAPI";


class EventService {

    static get_all_events() {

        return eventsAPI().get(`/events`).then((res) => {
            if (res !== undefined) {
                return res.data;
            } else {
                return []
            }

        }).catch((err) => {
            throw err;
        });
    }

    static get_event(eventId) {

        return eventsAPI().get(`/events/${eventId}`).then((res) => {
            if (res !== undefined) {
                return res.data;
            } else {
                return []
            }

        }).catch((err) => {
            throw err;
        });
    }

    static add_event(event) {

        const newEvent = {
            "event_name": event.eventName,
            "event_description": event.eventDescription,
            "start_time": new Date(event.eventStartDate)
        }

        return eventsAPI().post(`/events`, newEvent).then((res) => {
            return res.data;
        }).catch((err) => {
            throw err;
        });
    }

}

export default EventService;