import {Button, Col, FloatingLabel, Modal, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useEffect, useState} from "react";
import UserService from "../../services/UserService";
import {emailValidator, requiredValidator} from "../../utils/inputValidators";
import EventService from "../../services/EventService";


export const AddEvent = ({show, onHide, onCreated}) => {

    const [showDialog, setShowDialog] = useState(show);
    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState({});


    useEffect(() => {
        setShowDialog(show);
    }, [show]);

    const handleClose = () => {
        setShowDialog(false)
        setFormData({})
        setFormErrors({});
        onHide();
    }

    const handleEventAdd = () => {
        if (handleValidate()) {
            EventService.add_event(formData)
            onCreated && onCreated()
            handleClose()
        }
    }


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});

    }

    const handleDateInputChange = (event) => {
        const target = event.target;
        const value = target.value.replace(/[^0-9]/g, '');
        const name = target.name;

        let temp_val = value;

        if (value.length > 4) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2, 2) + '/' +
                temp_val.substr(4)
        } else if (value.length > 2) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2)
        }
        ;

        setFormData({
            ...formData,
            [name]: temp_val
        });
        target.value = temp_val;

        //reset error message if exists
        let errors = formErrors;
        delete errors[name];
        setFormErrors({...errors});

    }

    const handleValidate = () => {

        let errors = {};

        !requiredValidator(formData.eventName, 3) ?
            errors.eventName = "Event Name is required" :
            delete errors.eventName;

        !requiredValidator(formData.eventDescription, 3) ?
            errors.eventDescription = "Event Description is required" :
            delete errors.eventDescription;

        setFormErrors({...errors})

        return Object.keys(errors).length === 0;



    }

    const addEventForm =
        <>

            <div>
                <FloatingLabel
                    controlId="floatingInput"
                    label="EventDetails Name*"
                    className="mb-3 text-secondary"
                >
                    <Form.Control name="eventName" required type="input"
                                  maxLength={100}
                                  value={formData.eventName}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors.eventName}
                                  placeholder="First Name"
                    />
                    <Form.Control.Feedback type="invalid">{formErrors.eventName}</Form.Control.Feedback>
                </FloatingLabel>
            </div>
            <div>
                <FloatingLabel
                    controlId="floatingInput"
                    label="EventDetails Description*"
                    className="mb-3 text-secondary"
                >
                    <Form.Control name="eventDescription" required type="input"
                                  maxLength={100}
                                  value={formData.eventDescription}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors.eventDescription}
                                  placeholder="Last Name"
                    />
                    <Form.Control.Feedback type="invalid">{formErrors.eventDescription}</Form.Control.Feedback>
                </FloatingLabel>
            </div>

            <div>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Start Date*"
                    className="mb-3 text-secondary"
                >
                    <Form.Control name="eventStartDate" required type="input"
                                  maxLength="10"
                                  value={formData.eventStartDate}
                                  isInvalid={!!formErrors.eventStartDate}
                                  onChange={handleDateInputChange}
                                  placeholder="MM/DD/YYYY"
                                  autoComplete="disabled"/>
                    <Form.Control.Feedback type="invalid">{formErrors.eventStartDate}</Form.Control.Feedback>
                </FloatingLabel>

            </div>


        </>

    return (
        <>
            <Modal show={showDialog} onHide={handleClose}>
                <Modal.Header closeButton closeVariant='white' className="modal-header small">
                    <Modal.Title className="">New EventDetails</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {addEventForm}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn-default" onClick={handleEventAdd}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}