import {Card, Carousel, Col, Container, Row, Image} from "react-bootstrap";
import PageTitle from "../components/layout/PageTitle";
import {useState} from "react";


export const LandingPage = () => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Container>
            <PageTitle title="Welcome to MFC Events!"/>


            <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <Image className="d-block w-100" src={`${process.env.PUBLIC_URL}/images/54785630_l_normal_none.jpg`} alt="Landing Page Image"/>
                    <Carousel.Caption className="px-5 py-0" style={{background: 'rgba(0, 0, 0, 0.4)'}}>
                        <h3>Simplify Your Event Planning</h3>
                        <p className="d-none d-md-block">
                            Empower your organization with our intuitive event planning platform. Whether it's church
                            services, youth camps, or conferences, streamline roles and responsibilities with ease. Create unforgettable
                            experiences without the stress. Join us today and make every event a success!
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image className="d-block w-100" src={`${process.env.PUBLIC_URL}/images/119987612_l_normal_none.jpg`} alt="Landing Page Image"/>
                    <Carousel.Caption className="px-5 py-0" style={{background: 'rgba(0, 0, 0, 0.4)'}}>
                        {/*<h3>Seamless Coordination for Non-Profits</h3>*/}
                        <div className="fs-2">Seamless Coordination for Non-Profits</div>
                        <p className="d-none d-md-block">
                            Transform how you plan and execute events. Our platform is designed specifically for small non-profits,
                            helping you manage every detail from start to finish. Assign roles, track tasks, and ensure everyone is on
                            the same page. Start planning smarter, not harder.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>


            {/*Marketing Blurbs for the Landing Page*/}
            {/*Blurb 1: Simplify Your Event Planning*/}
            {/*Empower your organization with our intuitive event planning platform. Whether it's church services, youth*/}
            {/*camps, or conferences, streamline roles and responsibilities with ease. Create unforgettable experiences*/}
            {/*without the stress. Join us today and make every event a success!*/}

            {/*Blurb 2: Seamless Coordination for Non-Profits*/}
            {/*Transform how you plan and execute events. Our platform is designed specifically for small non-profits,*/}
            {/*helping you manage every detail from start to finish. Assign roles, track tasks, and ensure everyone is on*/}
            {/*the same page. Start planning smarter, not harder.*/}

            {/*Blurb 3: Efficient Event Management for Every Occasion*/}
            {/*Take the hassle out of organizing your next event. Our comprehensive event planning tool supports a variety*/}
            {/*of functions from church services to youth camps. Manage volunteers, assign responsibilities, and ensure*/}
            {/*smooth operations with our user-friendly platform.*/}

            {/*Blurb 4: Empower Your Team, Elevate Your Events*/}
            {/*Unlock the full potential of your team with our all-in-one event management solution. Perfect for*/}
            {/*non-profits, our platform simplifies the planning process, making it easy to assign roles and track*/}
            {/*progress. Experience stress-free event coordination and elevate your next gathering.*/}

            {/*Blurb 5: Plan with Confidence, Execute with Precision*/}
            {/*Say goodbye to chaotic event planning. Our platform offers robust tools to help non-profits organize*/}
            {/*everything from small meetings to large conferences. Delegate tasks, monitor progress, and ensure every*/}
            {/*detail is covered. Start planning with confidence today.*/}


        </Container>
    )
}

