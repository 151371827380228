export const requiredValidator = (value, minLength = 2) => {
    if (value == null) return false;
    const regex = new RegExp(`[a-z0-9\\w\\s]{${minLength},}`, 'i');
    return regex.test(value);
}


export const emailValidator = (email) => {
    const regex = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
    return regex.test(email);
};